import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateSearchQuery } from '@app/core/helpers/params-generator';

import { OpenedInvoicesStatsWidgetRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class OpenedInvoicesStatsService {
  constructor(private http: HttpClient) {}

  load(request: OpenedInvoicesStatsWidgetRequest) {
    return this.http.get(
      `statistics/opened_invoices_totals?${generateSearchQuery(request)}`,
    );
  }
}
