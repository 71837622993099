import {
  OpenedInvoicesStatsWidgetRequest,
  OpenedInvoicesStatsWidgetResponse,
} from '@app/models';
import { createAction, props } from '@ngrx/store';

export const loadWidgetsRequest = createAction(
  '[Opened Invoices Stats] Load Widgets Request',
  props<{ request: OpenedInvoicesStatsWidgetRequest }>(),
);

export const loadWidgetsSuccess = createAction(
  '[Opened Invoices Stats] Load Widgets Success',
  props<{ stats: OpenedInvoicesStatsWidgetResponse }>(),
);

export const loadWidgetsFailure = createAction(
  '[Opened Invoices Stats] Load Widgets Failure',
  props<{ error: any }>(),
);

export const reseState = createAction('[Opened Invoices Stats] Reset State');
